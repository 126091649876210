<template lang="pug">
  .root
    component-header-index

    div(class="container-flex")
      div(class="content-left")
        component-top
      div(class="content-right")
        div(class="right-content-border")
          button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>

          div Twitter拡散用テキスト
          div ※全角40文字、半角80文字以内
          div(class="twitter-text-content")
            textarea(v-model="inputText", :maxlength="propsLimit" class="input-g-w-border")
            div(class="text-count") {{ inputText ? stringCount : '0' }}/{{ propsLimit }}

          div Twitter拡散用テキスト(確認用)
          div(class="twitter-text-content-check")
            div(class="input-text") {{ inputTextPreview }}
            br
            div(style="color:red;") ※配信URL

          div(class="button-gemini")
            button(@click="onSubmit()" class="pink-button") 変更する
            button(@click="onClose()" class="pink-button") キャンセル

    component-footer-index
</template>

<style src="./Normal.scss" lang="scss" scoped>
</style>

<script>
import { setRoom, getRoom } from '@/api/room'
import { validStringCount, stringCount } from '@/utils/inputLimit';

export default {
  name: "SettingTwitterShareNormal",
  components: {},
  data() {
    return {
      inputText: '',
      roomName: '',
      propsLimit: 80
    }
  },
  computed: {
    inputTextPreview(){
      return this.inputText + "\n\n" + this.roomName;
    },
    stringCount() {
      return stringCount(this.inputText);
    },
  },
  watch: {},
  mounted() {},
  async created() {
    const ret = await getRoom();
    this.roomName = ret.data.name;
    this.inputText = ret.data.twitter_text
  },
  updated() {},
  destroyed() {},
  methods: {
    async onSubmit() {
      if (!validStringCount(this.inputText, 80)) {
        window.alert("全角40文字、半角80文字以内で入力してください");
        return;
      }
      await setRoom({
        twitter_text: this.inputText
      });
      this.$router.push({ name: 'TopIndex' })
    },
    onClose() {
      this.$router.push({ name: 'TopIndex' })
    }
  }
};
</script>
